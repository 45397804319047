import React from 'react';
import { Link } from 'react-scroll';
import { Parallax } from "react-scroll-parallax";
const config = require('../../config').default;

class Photos extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.listExperience = config.experience.map(this.buildList);
		this.listEducation = config.education.map(this.buildList);
		this.listSkills = config.sections.about.skills.map(this.buildSkills);
	}
	buildSkills(d) {
		return <div>{ d }</div>
	}
	buildList(d) {
		return <li key={d.id} className="">
		<div className="title">{ d.icon!="" && typeof d.icon!=="undefined"?<i className={d.icon}></i>:null } { d.title } { d.hasOwnProperty("client") ? <div>at <span>{ d.client}</span></div> : null}</div>
		<div className="subtitle">{ d.year }</div>
		<div className={ d.hasOwnProperty("major") ? "description" : "description description-hidden" }><span dangerouslySetInnerHTML={{ __html: d.description }}></span></div>
	</li>;
	}
	render() {
		return (
			<section id={this.props.id}>
				<div>
					<div className="container">
						<Parallax translateY={[-config.header_offset, config.header_offset]}><h3>About Me</h3></Parallax>
						<p>{ config.sections.about.text }</p>
						<h4>Skills</h4>
						<div className="skills">
							{this.listSkills}
						</div>
						<div id="education">
							<h4>Education</h4>
							<ul className="list">{ this.listEducation }</ul>
						</div>
						<div id="experience">
							<h4>Experience</h4>
							<ul className="list">{ this.listExperience }</ul>
						</div>
					</div>
					<div className="arrow">
							<Link to="portfolio" spy={true} smooth={true} duration={800}>
								<i className="fa-solid fa-angle-down"></i>
							</Link>
						</div>
				</div>
				
			</section>

		)
	}
}

export default Photos;