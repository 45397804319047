import React from 'react';
import { Parallax } from "react-scroll-parallax";
const config = require('../../config').default;

class Contact extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.links = config.sections.contact.links.map(this.buildIcons);
	}
	buildIcons(d,i) {
		return <div key={i}><a href={d.url} target="_blank"><i className={ d.icon }></i></a></div>
	}
	render() {
		return (
			<section id={this.props.id}>
				<div>
					<div className="container">
						{
						//Parallax translateY={[-config.header_offset, config.header_offset]}><h3>{config.sections.contact.title}</h3></Parallax>
						//<p>{ config.sections.contact.text }</p>
						}
						<div className="icon-list">{ this.links }</div>
					</div>
				</div>
			</section>

		)
	}
}

export default Contact;
