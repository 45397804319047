import React from "react";
class Modal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            shown: this.props.shown
        }
    }
    render() {
        return <div className={"modal " + this.props.className + " " + (this.props.shown ? "shown" : "")}>
            <div>
                <div className="modal-overlay" onClick={e => { this.props.exit(e); }}></div>
                <div className="container">
                    {!this.props.shown ? null :
                        <div>
                            {this.props.media === "video" ?
                                <div className="video-embed">
                                    {this.props.url !== "" ? <iframe width="100%" height="100%" src={this.props.url + "?autoplay=1&controls=0"} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> : null}
                                    <img src={this.props.thumb !== "" ? this.props.thumb : "assets/image/placeholder.png"} className="placeholder" alt={this.props.title} />
                                </div>
                                :
                                <img src={this.props.url} className="modal-image" alt={this.props.title} />
                            }
                        </div>
                    }
                </div>

            </div>


        </div>;
    }
}

export default Modal;