import React from 'react';
import { Link } from 'react-scroll';
import { Parallax } from "react-scroll-parallax";
const config = require('../../config').default;

class Home extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            disableParallax: false 
        };
		
    }
	componentDidMount() {
		window.addEventListener('resize', ()=>{ this.handleWindowSizeChange() });
		this.handleWindowSizeChange();
	}
	componentWillUnmount() {
		window.removeEventListener('resize', ()=>{ this.handleWindowSizeChange() });
	}
	handleWindowSizeChange() {
		var mobileWidth = 580,
			isMobileAspect = window.innerHeight>window.innerWidth;
		if(this.state.disableParallax && window.innerWidth>=mobileWidth && !isMobileAspect) {
			this.setState({disableParallax: false });
		}
		else if(!this.state.disableParallax && (window.innerWidth<mobileWidth || isMobileAspect)) {
			this.setState({disableParallax: true });
		}
		
	}

	video() {
		return <video autoPlay muted poster="assets/images/preview.jpg" id="intro" loop>
			<source src="assets/video/placeholder.webm" type="video/webm" />
			<source src="assets/video/placeholder.mp4" type="video/mp4" />
		</video>

	}
	render() {
		return (

			<section id={this.props.id} className={this.state.disableParallax?"mobile":null}>
				<Parallax className="background-video" translateY={[20, -20]}>

					<Parallax translateY={[-200, 200]}>
						<div>
							<div id="background"></div> 

						</div>

					</Parallax>
				</Parallax>
				<div>
					<div className="container">
						<Parallax translateY={['0px', '-50px']} disabled={this.state.disableParallax}>
							<h1>{config.sections.intro.title}</h1>
						</Parallax>
						<Parallax translateY={['0px', '-100px']} disabled={this.state.disableParallax}>
							<h2>{config.sections.intro.subtitle}</h2>
						</Parallax>
						<Parallax translateY={['0px', '-50px']} disabled={this.state.disableParallax}>
							<p>{config.sections.intro.text}</p>
						</Parallax>
						<div className="arrow">
							<Link to="about" spy={true} smooth={true} duration={800}>
								<i className="fa-solid fa-angle-down"></i>
							</Link>
						</div>
					</div>
				</div>
			</section>



		)
	}
}

export default Home;