import React from 'react';
import Home from './components/Home/Home';
import About from './components/About/About';
import Portfolio from './components/Portfolio/Portfolio';
import Photos from './components/Photos/Photos';
import Contact from './components/Contact/Contact';
import { Helmet } from "react-helmet";
import './sass/style.scss';

class Routes extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {};
	}
	render() {
		return (
			<div className="App">
				<Helmet>
					<title>Scott Hennessy</title>
					<meta name="description" content="" />
				</Helmet>
				<Home id="home" />
				<About id="about" />
				<Portfolio id="portfolio" />
				<Photos id="photography" />
				<Contact id="contact" />
				<div id="footer">
					<div className="container">
						© { new Date().getFullYear() } Scott Hennessy
					</div>
				</div>
			</div>
		)
	}
}

export default Routes;
