var config = {
    header_offset: 35,

    sections: {
        intro: {
            title: "Scott Hennessy",
            subtitle: "Drone - Photo - Video - Live Events",
            text: ""
        },
        about: {
            title: "About Me",
            text: "Hi there, I am a video professional and FAA licensed drone operator with 15 years of experience spanning across multiple aspects of the video and photo production process. I have worked on large scale live sporting events all the way down to small scale corporate videos. I would love the opportunity to discuss how I can be an asset to your team.",
            skills: ["Video Production","Editing","Premiere Pro","Final Cut","DaVinci Resolve","After Effects","Compositing","Motion Graphics","Photography","Color Correction","Lightroom","Photoshop","Illustrator","FAA Licensed Drone Pilot","Aerial Imaging (Helicopter)"]
        },
        portfolio: {
            title: "Video",
        },
        photography: {
            title: "Photography",
        },
        contact: {
            title: "Contact Me",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sagittis fermentum leo, a feugiat nibh elementum sit amet. Quisque vel convallis eros. Etiam finibus nulla at purus rutrum pulvinar. Nam at lorem fringilla, rutrum ipsum quis, ornare tellus. Morbi tincidunt tempus nisi non commodo. Nullam id ligula sit amet ante luctus consequat.",
            links: [
                {
                    "icon": "fa-solid fa-at",
                    "url": "mailto:scottarhennessy@gmail.com",
                },
                {
                    "icon": "fa-solid fa-phone",
                    "url": "tel:7723612764",
                },
                {
                    "icon": "fa-brands fa-linkedin-in",
                    "url": "https://www.linkedin.com/in/scottarhennessy/",
                },
                {
                    "icon": "fa-brands fa-youtube",
                    "url": "https://www.youtube.com/user/ScottARHennessy",
                },
                {
                    "icon": "fa-brands fa-vimeo-v",
                    "url": "https://vimeo.com/scotthennessy",
                }
            ]
        }
    },
    video:
        [
                        {
                "id": 500,
                "featured": false,
                "title": "2023 Showreel",
                "description": "",
                "thumb": "assets/images/thumbs/2023_thumb.jpg",
                "full": "https://www.youtube.com/embed/JJKU9A9yNFU",
                "order": 1,
                "active": "1",
                "type": "video"
            },
            {
                "id": 501,
                "featured": false,
                "title": "Restoring the Loxahatchee River",
                "description": "",
                "thumb": "assets/images/thumbs/Lox_Thumb.png",
                "full": "https://www.youtube.com/embed/_gbv--PRXkk",
                "order": 3,
                "active": "1",
                "type": "video"
            },
                        {
                "id": 502,
                "featured": false,
                "title": "Groundbreaking for the Biscayne Bay Coastal Wetlands",
                "description": "",
                "thumb": "assets/images/thumbs/Biscayne_Live_Thumb.png",
                "full": "https://www.youtube.com/embed/lWdwI3QqRKQ",
                "order": 3,
                "active": "1",
                "type": "video"
            },
            {
                "id": 503,
                "featured": false,
                "title": "Hurricane Ian Update — Tuesday, September 27, 2022",
                "description": "",
                "thumb": "assets/images/thumbs/Hurricane_Ian_Update_Thumb.png",
                "full": "https://www.youtube.com/embed/8vKsd7v5tVk",
                "order": 3,
                "active": "1",
                "type": "video"
            },
            {
                "id": 17,
                "featured": false,
                "title": "2018 Showreel",
                "description": "",
                "thumb": "assets/image/thumb/2018 Showreel.png",
                "full": "https://www.youtube.com/embed/oDFXp_eqau8",
                "order": 1,
                "active": "1",
                "type": "video"
            },

            {
                "id": 10,
                "featured": false,
                "title": "Deciphering Emojis - Full",
                "description": "",
                "thumb": "assets/image/thumb/Deciphering Emojis - Full.png",
                "full": "https://www.youtube.com/embed/TdSeR7UgRyw&",
                "order": 3,
                "active": "1",
                "type": "video"
            },
           /* {
                "id": 9,
                "featured": false,
                "title": "Deciphering Emojis - Eggplant",
                "description": "",
                "thumb": "assets/image/thumb/Deciphering Emojis - Eggplant.png",
                "full": "https://www.youtube.com/embed/6pj3aeEIIr0",
                "order": 4,
                "active": "1",
                "type": "video"
            },
            {
                "id": 8,
                "featured": false,
                "title": "Nitrogen Spot",
                "description": "",
                "thumb": "assets/image/thumb/Nitrogen Spot.png",
                "full": "https://www.youtube.com/embed/mBeSIeU0AcI",
                "order": 5,
                "active": "1",
                "type": "video"
            },*/
            {
                "id": 1,
                "featured": false,
                "title": "Labour Conference Exhibition 2013",
                "description": "",
                "thumb": "assets/image/thumb/Labour Conference Exhibition 2013.png",
                "full": "https://www.youtube.com/embed/m3m0Ma5E9jw",
                "order": 6,
                "active": "1",
                "type": "video"
            },
            {
                "id": 2,
                "featured": false,
                "title": "Sands of Time",
                "description": "",
                "thumb": "assets/image/thumb/Sands of Time.png",
                "full": "https://www.youtube.com/embed/wHdkDSfxShA",
                "order": 199,
                "active": "1",
                "type": "video"
            },
            {
                "id": 4,
                "featured": false,
                "title": "St Dunstan's Garden",
                "description":"",
                "thumb": "assets/image/thumb/St Dunstan's Garden.png",
                "full": "https://www.youtube.com/embed/sNElRSpc2GU",
                "order": 200,
                "active": "1",
                "type": "video"
            },
            {
                "id": 26,
                "featured": false,
                "title": "Maxine's Spot",
                "description": "",
                "thumb": "assets/image/thumb/Maxine's Spot.png",
                "full": "https://www.youtube.com/embed/a0IIGVPxPk0",
                "order": 200,
                "active": "1",
                "type": "video"
            }
        ],
    photo:
        [
           {
                "id": 100,
                "title": "Sunset Over the Everglades",
                "description": "Sunset over Water Conservation Area 3. Taken while working for SFWMD",
                "thumb": "assets/images/portfolio/thumbs/2019_Dec_WCA_3_Sunset_web_thumb.jpg",
                "full": "assets/images/portfolio/2019_Dec_WCA_3_Sunset_web.jpg",
                "order": 1,
                "active": "1",
                "type": "image"
            },
            {
                "id": 101,
                "title": "Edge of the Bay",
                "description": "Mangroves are an important feature in Florida Bay. Taken while working for SFWMD",
                "thumb": "assets/images/portfolio/thumbs/230113_Florida_Bay_07630_web_thumb.jpg",
                "full": "assets/images/portfolio/230113_Florida_Bay_07630_web.jpg",
                "order": 1,
                "active": "1",
                "type": "image"
            },
                        {
                "id": 102,
                "title": "Mud-ringing",
                "description": "A rare foraging behavior, dolphins corral fish by kicking up sediment. As the fish jump to escape they are met by hungry dolphins on the other side. Taken while working for SFWMD",
                "thumb": "assets/images/portfolio/thumbs/230113_Florida_Bay_07757_Web_thumb.jpg",
                "full": "assets/images/portfolio/230113_Florida_Bay_07757_Web.jpg",
                "order": 1,
                "active": "1",
                "type": "image"
            },
            {
                "id": 103,
                "title": "Wetlands Restored",
                "description": "Anhingas flying towards a small pump station in the Lake Hicpochee restoration area. Taken while working for SFWMD",
                "thumb": "assets/images/portfolio/thumbs/210107_Hicpochee_Snail_Kite_156_web_thumb.jpg",
                "full": "assets/images/portfolio/210107_Hicpochee_Snail_Kite_156_web.jpg",
                "order": 1,
                "active": "1",
                "type": "image"
            },
            {
                "id": 104,
                "title": "Welcome to Miami",
                "description": "Aerial view of the city taken on the way back from Florida Bay. Taken while working for SFWMD",
                "thumb": "assets/images/portfolio/thumbs/210107_230113_Miami_08000_web_thumb.jpg",
                "full": "assets/images/portfolio/230113_Miami_08000_web.jpg",
                "order": 1,
                "active": "1",
                "type": "image"
            },
           /* {
                "id": 18,
                "title": "Johnny Longboats - Singer Island",
                "description": "Mixed drink from Johnny Longboats on Singer Island - Quisineclub.com",
                "thumb": "assets/images/portfolio/thumbs/JLD-Thumb.jpg",
                "full": "assets/images/portfolio/JLD.jpg",
                "order": 1,
                "active": "1",
                "type": "image"
            },
            {
                "id": 19,
                "title": "Johnny Longboats - Singer Island",
                "description": "Dessert from Johnny Longboats on Singer Island - Qusineclub.com",
                "thumb": "assets/images/portfolio/thumbs/JLP-Thumb.jpg",
                "full": "assets/images/portfolio/JLP.jpg",
                "order": 1,
                "active": "1",
                "type": "image"
            },*/
            {
                "id": 20,
                "title": "Mai Kai - Ft. Lauderdale",
                "description": "Surf and Turf from Mai Kai Restaurant - Localdines.com",
                "thumb": "assets/images/portfolio/thumbs/MKST-Thumb.jpg",
                "full": "assets/images/portfolio/MKST.jpg",
                "order": 1,
                "active": "1",
                "type": "image"
            },/*
            {
                "id": 21,
                "title": "Maxine's - Taquesta",
                "description": "Penne pasta dish from Maxine's in Jupiter. - Localdines.com",
                "thumb": "assets/images/portfolio/thumbs/MXP-Thumb.jpg",
                "full": "assets/images/portfolio/MXP.jpg",
                "order": 1,
                "active": "1",
                "type": "image"
            },
            {
                "id": 22,
                "title": "Ninja Thai - PSL",
                "description": "Shot of the Owner of Ninja Thai Asian Fusion in Port Saint Lucie. - Quisineclub.com",
                "thumb": "assets/images/portfolio/thumbs/NTO-Thumb.jpg",
                "full": "assets/images/portfolio/NTO.jpg",
                "order": 1,
                "active": "1",
                "type": "image"
            },*/
            {
                "id": 105,
                "title": "Grassy Waters",
                "description": "Grassy Waters Preserve serves as an important source of freshwater for many towns in Palm Beach County. Taken while working for SFWMD.",
                "thumb": "assets/images/thumbs/221007_Flight_01542_web_Thumb.jpg",
                "full": "assets/images/portfolio/221007_Flight_01542_web.jpg",
                "order": 200,
                "active": "1",
                "type": "image"
            },
            {
                "id": 23,
                "title": "PapiChulo Tacos - Jupiter",
                "description": "Pollo Asado - Quisineclub.com",
                "thumb": "assets/images/portfolio/thumbs/PCT-Thumb.jpg",
                "full": "assets/images/portfolio/PCT.jpg",
                "order": 1,
                "active": "1",
                "type": "image"
            },/*
            {
                "id": 24,
                "title": "PapiChulo Tacos - Jupiter",
                "description": "Pescado Frito - Quisineclub.com",
                "thumb": "assets/images/portfolio/thumbs/PCF-Thumb.jpg",
                "full": "assets/images/portfolio/PCF.jpg",
                "order": 1,
                "active": "1",
                "type": "image"
            },*/
            
               {
                "id": 106,
                "title": "Coastline",
                "description": "Taken while working for SFWMD.",
                "thumb": "assets/images/thumbs/230113_Florida_Bay-07932_web.jpg",
                "full": "assets/images/portfolio/230113_Florida_Bay-07932_web.jpg",
                "order": 200,
                "active": "1",
                "type": "image"
            },  
            {
                "id": 11,
                "title": "Don Chepos - Wellington",
                "description": "Shot for QuisineClub. Birdseye view of a variety of menue items prepared at Don Chepos Taco Shop in Wellington, FL.",
                "thumb": "assets/images/thumbs/DCThumb1.jpg",
                "full": "assets/images/portfolio/DC1.jpg",
                "order": 200,
                "active": "1",
                "type": "image"
            },
                        {
                "id": 12,
                "title": "Character Promo Shoot",
                "description": "Character demo headshot for a stage play.",
                "thumb": "assets/images/thumbs/BobThumb.jpg",
                "full": "assets/images/portfolio/BobCharacter.jpg",
                "order": 200,
                "active": "1",
                "type": "image"
            },
            {
                "id": 13,
                "title": "Caffe Martier - Delray Beach",
                "description": "Shot for LocalDines.com. Caffe Martier in Delray Beach, FL prepared this fantastic Snapper for the photoshoot.",
                "thumb": "assets/images/thumbs/CMThumb.jpg",
                "full": "assets/images/portfolio/CM.jpg",
                "order": 200,
                "active": "1",
                "type": "image"
            },
            {
                "id": 14,
                "title": "Trattoria Dario - Vero Beach",
                "description": "Shot for Localdines.com. This desert prepared by Tratoria Dario in Vero Beach, FL. Shot on a long lens I was able to capture this desert in the natuarly flattering diffuesed light of their outside patio.",
                "thumb": "assets/images/thumbs/DMDThumb.jpg",
                "full": "assets/images/portfolio/DMD.jpg",
                "order": 200,
                "active": "1",
                "type": "image"
            },
            {
                "id": 15,
                "title": "Ninja Thai - PSL",
                "description": "Shot for QuisineClub",
                "thumb": "assets/images/thumbs/NTSThumb.jpg",
                "full": "assets/images/portfolio/NTS.jpg",
                "order": 200,
                "active": "1",
                "type": "image"
            }
        ],
    tours:
        [
            {
                "id": 1,
                "title": "The Butcher Shop WPB 360",
                "thumb": "assets/images/thumbs/BSThumb.jpg",
                "full": "https://goo.gl/maps/QfnbkwTwyLB2",
                "order": 1,
                "active": "1"
            },
            {
                "id": 2,
                "title": "Mai-Kai Fort Lauderdale 360",
                "thumb": "assets/images/thumbs/MaiKaiThumb.jpg",
                "full": "https://goo.gl/maps/6sxoQT5tYYS2",
                "order": 2,
                "active": "1"
            },
            {
                "id": 3,
                "title": "2nd Street Bistro",
                "thumb": "assets/images/thumbs/2SBThumb.jpg",
                "full": "https://goo.gl/maps/4zVVkybhQYM2",
                "order": 3,
                "active": "1"
            }
        ],
    experience:
        [
            {
                "id": 0,
                "icon": "",
                "year": "2019 - Present",
                "title": "Public Affairs Videographer",
                "client": "South Florida Water Management District",
                "description":  `Lead videographer/photographer for the South Florida Water Management District - the regional governmental agency responsible for managing the water resources for millions of Floridians and advancing the largest and most ambitious ecosystem restoration effort in the world - Restoring America's Everglades. Duties include:
<ul>                
<li> Creating superior quality imagery in a fast-paced environment for social media, public board meetings, and other communication channels.</li>
                
<li>Executing live-stream productions for events, press conferences, emergency operations, and other educational programs. Many of these events are often produced in remote regions throughout Central and South Florida.</li>
                
<li>Conduct unmanned aerial operations to produce high-quality video and photography for internal and external use by the District.</li>
                
<li>Maintaining and operating the in-house studio and live truck for remote and studio-based productions. In this role, I designed, executed, and oversaw the in-house studio construction and live truck refit.</li>
                
<li>Emergency response duties. This role includes operating the studio during emergency operations to facilitate live updates to the public and live interviews with local and national media outlets.</li> 
                
<li>Capturing Aerial photos and video from District helicopters. Responsible for capturing critical video and photos to document the progress of crucial restoration projects and damage assessment imagery of infrastructure following major storm events.</li>
                
<li>Providing all necessary technical advice and guidance required for the application of strategic photo and audio-visual communication.</li>
                
<li>Schedule, plan, and coordinate required photo, video, motion graphics, and audio needs for various projects, along with collecting and updating media assets from other departments.</li>
</ul>`,
                "order": 0
            },
            {
                "id": 1,
                "icon": "",
                "year": "2014 - 2019",
                "title": "Videographer",
                "client": "LocalDines.com",
                "description": "I Provided video, photo, and Google 360 Tours for restaurant clients. From initial scheduling to post-production, I handled all aspects of the shoot. Secondary duties included graphic design work and some light front-end web development.",
                "order": 1
            },
            {
                "id": 10,
                "icon": "",
                "year": "2016",
                "title": "Freelance Videographer",
                "client": "Fractl",
                "description": "shot and edited videos for multiple client projects. These ranged from simple vox pops to creating motion graphics for videos.",
                "order": 2
            },
            {
                "id": 2,
                "icon": "",
                "year": "2014",
                "title": "Photographer",
                "client": "Inlet Pics",
                "description": "Captured action shots of boats leaving and entering the inlet. Upload pictures via FTP to server for review.",
                "order": 3
            },
            {
                "id": 3,
                "icon": "",
                "year": "2013",
                "title": "Camera Operator",
                "client": "Labour Party Conference",
                "description": "I was part of a two-person team that created a short highlight video of the Labour Party Conference in Brighton. We used Multiple DSLRs to capture construction time-lapses and allow us to film interviews with attendees.",
                "order": 4
            },
            {
                "id": 4,
                "icon": "fa-solid fa-crown",
                "year": "2012",
                "title": "CAMERA ASSISTANT",
                "client": "Olympic Broadcasting Service",
                "description": "I assisted with coverage of the 2012 London Olympic Games. I worked with United, a Dutch broadcaster, to build camera positions and run cables long distances to cover the cycle time trials and marathon events outside Buckingham Palace and Hampton Court Palace.",
                "order": 5
            },
            {
                "id": 6,
                "icon": "fa-solid fa-crown",
                "year": "2012",
                "title": "Camera Assistant",
                "client": "ONTV Ltd UK",
                "description": "Camera and audio assistant, working on behalf of ITN, shooting live and prerecorded pieces to camera for various international broadcasters during the Queen Elizabeth’s Diamond Jubilee celebrations In London.",
                "order": 7
            },
            {
                "id": 7,
                "icon": "",
                "year": "2011",
                "title": "Sound Supervisor & Stage Lighting Assistant",
                "client": "The Rose Theatre",
                "description": "Worked with directors and actors during rehearsals to hone audio cues and levels for music and audio effects. I also assisted the Lighting Director with building out custom lighting solutions for the stage and created special lighting effects for various plays.",
                "order": 8
            },
            {
                "id": 8,
                "icon": "",
                "year": "2011",
                "title": "Production Assistant/ Sound Operator",
                "client": "Spectrecom",
                "description": "Operated audio equipment during interviews and assisted with cutaway shots for a corporate video at Sussex University",
                "order": 9
            },
            {
                "id": 9,
                "icon": "fa-solid fa-crown",
                "year": "2011",
                "title": "Camera Assistant, Sound Operator",
                "client": "Royal Wedding with ONTV",
                "description": "I Built and Maintained A/V and lighting setups outside of Buckingham Palace and Westminister Abbey on behalf of ITN. Various international broadcasters used these setups during the royal wedding of Prince William and Kate Middleton.",
                "order": 10
            }
        ],
    education:
        [
            {
                "id": 2,
                "year": "2009 - 2012",
                "title": "Bachelor's degree - Television Production",
                "major": "Production",
                "description": "Ravensbourne College of Design and Communication",
                "order": 1
            },
            {
                "id": 4,
                "year": "2007 - 2009",
                "title": "Btec national diploma - TV & Film",
                "major": "TV & Film",
                "description": "The Ravensbourne School",
                "order": 3
            }
        ]

};


export default config;
