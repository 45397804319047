import React, { Component } from "react";
import Routes from './Routes';
import { ParallaxProvider } from "react-scroll-parallax";

export default class App extends Component {
  render() {
    return (
      <ParallaxProvider>
        <Routes />
      </ParallaxProvider>
    );
  }
}