import React from 'react';
import { Link } from 'react-scroll';
import { Parallax } from "react-scroll-parallax";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Modal from "../Modal/Modal";

const config = require('../../config').default;

class Photos extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			active: null,
			modal_shown: false,
			modal_url: "",
			modal_thumb: "https://via.placeholder.com/800x450?text=test",
			modal_title: ""
		}
		this.photoGallery = config.photo.map((d,i) => { return this.buildGallery(d,i) });
	}
	modal(d) {
		this.setState({
			current_photo: d.id,
			modal_shown: true,
			modal_url: d.full,
			modal_thumb: "https://via.placeholder.com/800x450?text="+d.title,
			modal_title: d.title
		});
	}
	buildGallery(d,i) {
		//console.log(d.id);
		return  <div key={i} onClick={() => this.modal(d)} className="gallery-item">
		<img src={ d.full } alt={d.title} />
		<div className="gallery-overlay">
			<div className="title">{d.title}</div>
			<div className="description">{d.description}</div>
		</div>
	</div>;
		
	}
	render() {
		return (
			<section id={this.props.id}>
				<div>
					<div className="container container-lg">
						<Parallax translateY={[-config.header_offset, config.header_offset]}><h3>{config.sections.photography.title}</h3></Parallax>

						<ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
							<Masonry className="masonry" gutter="2px">
								{this.photoGallery}
							</Masonry>
						</ResponsiveMasonry>
					</div>
					<div className="arrow">
						<Link to="contact" spy={true} smooth={true} duration={800}>
							<i className="fa-solid fa-angle-down"></i>
						</Link>
					</div>
					<Modal exit={ (e) =>{ this.setState({ modal_shown: false }) } } shown={this.state.modal_shown} media="photo" url={this.state.modal_url} thumb={this.state.modal_thumb} />
				
				</div>
			</section>

		)
	}
}

export default Photos;