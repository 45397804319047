import React from 'react';
import { Link } from 'react-scroll';
import { Parallax } from "react-scroll-parallax";
import Modal from "../Modal/Modal";
const config = require('../../config').default;

class Portfolio extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			active: null,
			modal_shown: false,
			modal_url: "",
			modal_thumb: "https://via.placeholder.com/800x450?text=test",
			modal_title: ""
		}
	}
	modal(d) {
		this.setState({
			current_video: d.id,
			modal_shown: true,
			modal_url: d.full,
			modal_thumb: "https://via.placeholder.com/800x450?text="+d.title,
			modal_title: d.title
		});
	}
	videoEmbed(d) {
		return <div className="video-embed">
			<iframe width="100%" height="100%" src={ d.full+"?autoplay=1&controls=0" } title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
			<img src={ "https://via.placeholder.com/800x450?text="+d.title } className="placeholder" alt={d.title} />
		</div>
	}
	videoGrid(d) {
		return <div key={d.id} className={d.id === this.state.current_video ? "active" : d.featured === true ? "lg" : null}>
			<div> <div className="gallery-item" onClick={e => { this.modal(d); }}>
						<img src={ d.thumb } alt={d.title} />
						<div className="gallery-overlay">
							<div className="title">{d.title}</div>
							<div className="description">{d.description}</div>
						</div>
					</div>

			</div>
		</div>;
	}


	render() {
		return (
			<section id={this.props.id}>
				<div>
					<div className="container container-lg">
						<Parallax translateY={[-config.header_offset, config.header_offset]}><h3 className="animated" data-duration="1200">{config.sections.portfolio.title}</h3></Parallax>
						<div className="wrapper">
							{config.video.map((d) => this.videoGrid(d))}
						</div>
					</div>
				
					<div className="arrow">
						<Link to="photography" spy={true} smooth={true} duration={800}>
							<i className="fa-solid fa-angle-down"></i>
						</Link>
					</div>
					<Modal exit={ (e) =>{ this.setState({ modal_shown: false }) } } shown={this.state.modal_shown} media="video" url={this.state.modal_url} thumb={this.state.modal_thumb} />
				</div>
			</section>
		)
	}

}

export default Portfolio;